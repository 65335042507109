<template>
  <div class="relative z-0 flex h-dvh flex-col items-center justify-between bg-primary px-4">
    <NuxtImg
      :src="logo"
      class="mt-20 w-full max-w-sm px-4"
      alt=""
    />

    <div class="relative w-full max-w-sm flex-1 px-4">
      <slot />
    </div>

    <img
      src="~/assets/images/road.svg"
      class="absolute inset-x-0 bottom-0 -z-10 object-cover"
      alt=""
    >
  </div>
</template>

<script setup lang="ts">
const {logo} = useApp();

useHead({
  htmlAttrs: {
    class: 'dark',
  },
});
</script>
